import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import Contact from "./components/Contact.vue";
import NotFound from "./components/NotFound.vue";
import Services from "./components/Services.vue";
import StartMyLaunch from "./components/StartMyLaunch.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Mile 20 Consulting - Top-Tier Technical Talent Specialists",
      metaTags: [
        {
          name: "description",
          content:
            "Mile 20 Consulting specializes in recruiting top-tier technical talent at mid to senior levels. Our expertise covers a wide range of specialties, including Senior Software Engineers and Developers, Python, C and C++ experts, FPGA wizards, Embedded Systems gurus, skilled professionals in Radar, Microwave, and Electrical Engineering.",
        },
        {
          property: "og:description",
          content:
            "Mile 20 Consulting specializes in recruiting top-tier technical talent at mid to senior levels. Our expertise covers a wide range of specialties, including Senior Software Engineers and Developers, Python, C and C++ experts, FPGA wizards, Embedded Systems gurus, skilled professionals in Radar, Microwave, and Electrical Engineering.",
        },
        {
          property: "author",
          content: "Mile 20 Consulting",
        },
        {
          property: "robots",
          content: "index, follow",
        },
        {
          property: "language",
          content: "English",
        },
        {
          property: "keywords",
          content:
            "Mile 20 Consulting, technical talent, job recruiting, Senior Software Engineers, Python experts, C++ experts, FPGA, Embedded Systems, Radar, Microwave, Electrical Engineering",
        },
        {
          property: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
        {
          property: "og:title",
          content: "Mile 20 Consulting - Top-Tier Technical Talent Specialists",
        },
        {
          property: "og:image",
          content: "https://mile20consulting.com/logo.png",
        },
        {
          property: "og:url",
          content: "https://mile20consulting.com",
        },
        {
          property: "og:site_name",
          content: "Mile 20 Consulting - Top-Tier Technical Talent Specialists",
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:title",
          content: "Mile 20 Consulting - Top-Tier Technical Talent Specialists",
        },
        {
          property: "twitter:description",
          content:
            "Mile 20 Consulting specializes in recruiting top-tier technical talent at mid to senior levels. Our expertise covers a wide range of specialties, including Senior Software Engineers and Developers, Python, C and C++ experts, FPGA wizards, Embedded Systems gurus, skilled professionals in Radar, Microwave, and Electrical Engineering.",
        },
      ],
    },
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
    title:
      "Mile 20 Consulting - Top-Tier Technical Talent Specialists - Services",
    metaTags: [
      {
        name: "description",
        content:
          "Mile 20 Consulting specializes in recruiting top-tier technical talent at mid to senior levels. Our expertise covers a wide range of specialties, including Senior Software Engineers and Developers, Python, C and C++ experts, FPGA wizards, Embedded Systems gurus, skilled professionals in Radar, Microwave, and Electrical Engineering.",
      },
      {
        property: "og:description",
        content:
          "Mile 20 Consulting specializes in recruiting top-tier technical talent at mid to senior levels. Our expertise covers a wide range of specialties, including Senior Software Engineers and Developers, Python, C and C++ experts, FPGA wizards, Embedded Systems gurus, skilled professionals in Radar, Microwave, and Electrical Engineering.",
      },
      {
        property: "author",
        content: "Mile 20 Consulting",
      },
      {
        property: "robots",
        content: "index, follow",
      },
      {
        property: "language",
        content: "English",
      },
      {
        property: "keywords",
        content:
          "Mile 20 Consulting, technical talent, job recruiting, Senior Software Engineers, Python experts, C++ experts, FPGA, Embedded Systems, Radar, Microwave, Electrical Engineering",
      },
      {
        property: "viewport",
        content: "width=device-width, initial-scale=1.0",
      },
      {
        property: "og:title",
        content: "Mile 20 Consulting - Top-Tier Technical Talent Specialists",
      },
      {
        property: "og:image",
        content: "https://mile20consulting.com/logo.png",
      },
      {
        property: "og:url",
        content: "https://mile20consulting.com",
      },
      {
        property: "og:site_name",
        content: "Mile 20 Consulting - Top-Tier Technical Talent Specialists",
      },
      {
        property: "twitter:card",
        content: "summary_large_image",
      },
      {
        property: "twitter:title",
        content: "Mile 20 Consulting - Top-Tier Technical Talent Specialists",
      },
      {
        property: "twitter:description",
        content:
          "Mile 20 Consulting specializes in recruiting top-tier technical talent at mid to senior levels. Our expertise covers a wide range of specialties, including Senior Software Engineers and Developers, Python, C and C++ experts, FPGA wizards, Embedded Systems gurus, skilled professionals in Radar, Microwave, and Electrical Engineering.",
      },
    ],
  },
  {
    path: "/start-my-launch",
    name: "Start My Launch",
    component: StartMyLaunch,
    meta: {
      title:
        "Mile 20 Consulting - Top-Tier Technical Talent Specialists - Start My Launch",
      metaTags: [
        {
          name: "description",
          content:
            "Mile 20 Consulting specializes in recruiting top-tier technical talent at mid to senior levels. Our expertise covers a wide range of specialties, including Senior Software Engineers and Developers, Python, C and C++ experts, FPGA wizards, Embedded Systems gurus, skilled professionals in Radar, Microwave, and Electrical Engineering.",
        },
        {
          property: "og:description",
          content:
            "Mile 20 Consulting specializes in recruiting top-tier technical talent at mid to senior levels. Our expertise covers a wide range of specialties, including Senior Software Engineers and Developers, Python, C and C++ experts, FPGA wizards, Embedded Systems gurus, skilled professionals in Radar, Microwave, and Electrical Engineering.",
        },
        {
          property: "author",
          content: "Mile 20 Consulting",
        },
        {
          property: "robots",
          content: "index, follow",
        },
        {
          property: "language",
          content: "English",
        },
        {
          property: "keywords",
          content:
            "Mile 20 Consulting, technical talent, job recruiting, Senior Software Engineers, Python experts, C++ experts, FPGA, Embedded Systems, Radar, Microwave, Electrical Engineering",
        },
        {
          property: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
        {
          property: "og:title",
          content: "Mile 20 Consulting - Top-Tier Technical Talent Specialists",
        },
        {
          property: "og:image",
          content: "https://mile20consulting.com/logo.png",
        },
        {
          property: "og:url",
          content: "https://mile20consulting.com",
        },
        {
          property: "og:site_name",
          content: "Mile 20 Consulting - Top-Tier Technical Talent Specialists",
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:title",
          content: "Mile 20 Consulting - Top-Tier Technical Talent Specialists",
        },
        {
          property: "twitter:description",
          content:
            "Mile 20 Consulting specializes in recruiting top-tier technical talent at mid to senior levels. Our expertise covers a wide range of specialties, including Senior Software Engineers and Developers, Python, C and C++ experts, FPGA wizards, Embedded Systems gurus, skilled professionals in Radar, Microwave, and Electrical Engineering.",
        },
      ],
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    title:
      "Mile 20 Consulting - Top-Tier Technical Talent Specialists - Contact",
    metaTags: [
      {
        name: "description",
        content:
          "Mile 20 Consulting specializes in recruiting top-tier technical talent at mid to senior levels. Our expertise covers a wide range of specialties, including Senior Software Engineers and Developers, Python, C and C++ experts, FPGA wizards, Embedded Systems gurus, skilled professionals in Radar, Microwave, and Electrical Engineering.",
      },
      {
        property: "og:description",
        content:
          "Mile 20 Consulting specializes in recruiting top-tier technical talent at mid to senior levels. Our expertise covers a wide range of specialties, including Senior Software Engineers and Developers, Python, C and C++ experts, FPGA wizards, Embedded Systems gurus, skilled professionals in Radar, Microwave, and Electrical Engineering.",
      },
      {
        property: "author",
        content: "Mile 20 Consulting",
      },
      {
        property: "robots",
        content: "index, follow",
      },
      {
        property: "language",
        content: "English",
      },
      {
        property: "keywords",
        content:
          "Mile 20 Consulting, technical talent, job recruiting, Senior Software Engineers, Python experts, C++ experts, FPGA, Embedded Systems, Radar, Microwave, Electrical Engineering",
      },
      {
        property: "viewport",
        content: "width=device-width, initial-scale=1.0",
      },
      {
        property: "og:title",
        content: "Mile 20 Consulting - Top-Tier Technical Talent Specialists",
      },
      {
        property: "og:image",
        content: "https://mile20consulting.com/logo.png",
      },
      {
        property: "og:url",
        content: "https://mile20consulting.com",
      },
      {
        property: "og:site_name",
        content: "Mile 20 Consulting - Top-Tier Technical Talent Specialists",
      },
      {
        property: "twitter:card",
        content: "summary_large_image",
      },
      {
        property: "twitter:title",
        content: "Mile 20 Consulting - Top-Tier Technical Talent Specialists",
      },
      {
        property: "twitter:description",
        content:
          "Mile 20 Consulting specializes in recruiting top-tier technical talent at mid to senior levels. Our expertise covers a wide range of specialties, including Senior Software Engineers and Developers, Python, C and C++ experts, FPGA wizards, Embedded Systems gurus, skilled professionals in Radar, Microwave, and Electrical Engineering.",
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
    meta: {
      title:
        "Mile 20 Consulting - Top-Tier Technical Talent Specialists - 404 Not Found",
      metaTags: [
        {
          name: "description",
          content:
            "Mile 20 Consulting specializes in recruiting top-tier technical talent at mid to senior levels. Our expertise covers a wide range of specialties, including Senior Software Engineers and Developers, Python, C and C++ experts, FPGA wizards, Embedded Systems gurus, skilled professionals in Radar, Microwave, and Electrical Engineering.",
        },
        {
          property: "og:description",
          content:
            "Mile 20 Consulting specializes in recruiting top-tier technical talent at mid to senior levels. Our expertise covers a wide range of specialties, including Senior Software Engineers and Developers, Python, C and C++ experts, FPGA wizards, Embedded Systems gurus, skilled professionals in Radar, Microwave, and Electrical Engineering.",
        },
        {
          property: "author",
          content: "Mile 20 Consulting",
        },
        {
          property: "robots",
          content: "index, follow",
        },
        {
          property: "language",
          content: "English",
        },
        {
          property: "keywords",
          content:
            "Mile 20 Consulting, technical talent, job recruiting, Senior Software Engineers, Python experts, C++ experts, FPGA, Embedded Systems, Radar, Microwave, Electrical Engineering",
        },
        {
          property: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
        {
          property: "og:title",
          content: "Mile 20 Consulting - Top-Tier Technical Talent Specialists",
        },
        {
          property: "og:image",
          content: "https://mile20consulting.com/logo.png",
        },
        {
          property: "og:url",
          content: "https://mile20consulting.com",
        },
        {
          property: "og:site_name",
          content: "Mile 20 Consulting - Top-Tier Technical Talent Specialists",
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:title",
          content: "Mile 20 Consulting - Top-Tier Technical Talent Specialists",
        },
        {
          property: "twitter:description",
          content:
            "Mile 20 Consulting specializes in recruiting top-tier technical talent at mid to senior levels. Our expertise covers a wide range of specialties, including Senior Software Engineers and Developers, Python, C and C++ experts, FPGA wizards, Embedded Systems gurus, skilled professionals in Radar, Microwave, and Electrical Engineering.",
        },
      ],
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // routes to another page via anchor link if it exists
  scrollBehavior(to, from, savedPosition) {
    // If saved position is passed down, return saved position
    if (savedPosition) {
      return savedPosition;
    }

    // if hash is included, go to the element matching the anchor hash
    if (to.hash) {
      return { el: to.hash };
    }

    // Otherwise, return 0, 0
    return { top: 0, left: 0 };
  },
  linkActiveClass: "active",
  linkExactActiveClass: "active-glass",
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
