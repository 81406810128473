<template>
  <HeaderComponent
    :section-class="'hero-section-request'"
    :show-text="false"
    :header-text="'Start My Launch'"
  ></HeaderComponent>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="card mt-3 mb-3 shadow">
          <div class="card-body">
            <h3>Using the Launchpad</h3>
            <hr />
            <p>
              * Double check that your name, email, and phone number is correct
            </p>
            <p>
              * Leave a short message introducing yourself and any other
              information you feel is relevant!
            </p>

            <br /><br />
            <strong
              >Thank you for your interest! I'll get back to you ASAP.</strong
            >
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <ContactFormComponent
          :predefined-type="'Start My Launch'"
          :disabled="true"
          :quote-form="true"
          :launch-form="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ContactFormComponent from "./shared/ContactForm.vue";
import HeaderComponent from "./core/Header.vue";

export default {
  name: "PricingComponent",
  components: {
    ContactFormComponent,
    HeaderComponent,
  },
};
</script>
