<template>
  <div class="card mt-3 mb-3 shadow">
    <div v-if="title" class="card-header-font card-header bg-dark text-white">
      {{ title }}
    </div>
    <div class="card-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardComponent",
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>
