<template>
  <HeaderComponent :section-class="'hero-section-home'" :show-text="true" />
  <div id="what-we-do" class="container px-4 py-5">
    <h2 class="pb-2 border-bottom">What We Do</h2>
    <div class="row g-4 pt-2">
      <div class="col-12">
        <p class="head text-body-emphasis">
          We Prepare Your Launch. Whether you are seeking the next career move,
          a promotion, or an all out Job Search, we've got you! Click on 'Start
          My Launch' to begin.
        </p>
        <router-link to="/start-my-launch" class="icon-link">
          Start My Launch
          <i class="bi bi-chevron-right"></i>
        </router-link>
      </div>
    </div>
    <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
      <div class="feature col">
        <div
          class="feature-icon d-inline-flex align-items-center justify-content-center bg-primary bg-gradient fs-2 mb-3"
        >
          <i class="bi bi-clipboard-check text-white"></i>
        </div>
        <h3 class="fs-2 text-body-emphasis">Job Strategy Sessions</h3>
        <p>
          Simple strategy sessions to guide you through the journey. Spoiler
          alert! Goal setting & tracking, live interview prep and the STAR
          method of interviewing are just a few of our impactful sessions.
          You'll learn how to distinguish yourself from the pack, track search
          results and negotiate salaries.
        </p>
        <router-link to="/services#job-strategy-sessions" class="icon-link">
          See details
          <i class="bi bi-chevron-right"></i>
        </router-link>
      </div>
      <div class="feature col">
        <div
          class="feature-icon d-inline-flex align-items-center justify-content-center bg-primary bg-gradient fs-2 mb-3"
        >
          <i class="bi bi-file-person text-white"></i>
        </div>
        <h3 class="fs-2 text-body-emphasis">Resume Re-vamp</h3>
        <p>
          Let us refresh your resume to attract your desired audience and tell
          your compelling story. We work one on one with you to learn what makes
          your experience unique, then craft a resume to tailor to each
          opportunity you pursue.
        </p>
        <router-link to="/services#resume-redo" class="icon-link">
          See details
          <i class="bi bi-chevron-right"></i>
        </router-link>
      </div>
      <div class="feature col">
        <div
          class="feature-icon d-inline-flex align-items-center justify-content-center bg-primary bg-gradient fs-2 mb-3"
        >
          <i class="bi bi-flag text-white"></i>
        </div>
        <h3 class="fs-2 text-body-emphasis">Career Transitions</h3>
        <p>
          Want or need to make a career change? Let us help your metamorphosis.
        </p>
        <router-link to="/services#career-transitions" class="icon-link">
          See details
          <i class="bi bi-chevron-right"></i>
        </router-link>
      </div>
    </div>
  </div>
  <div
    class="container-fluid px-4 py-5 bg-dark text-white d-flex justify-content-center"
  >
    <div class="col-xxl-8 col-12">
      <h2 class="pb-2 border-bottom">What Our Clients Are Saying!</h2>
      <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
        <!-- <div class="col-10 col-sm-8 col-lg-6">
          <img
            src="../assets/images/coders-coding.jpg"
            class="d-block mx-lg-auto img-fluid border"
            alt="Programming"
            loading="lazy"
          />
        </div> -->
        <Carousel :items-to-show="1">
          <Slide v-for="slide in slides" :key="slide.title">
            <div class="row flex justify-content-center">
              <!-- <div class="col-12">
                <p>
                  {{ slide.title }}
                </p>
              </div> -->

              <div class="col-10">
                <p
                  v-for="(paragraph, idx) in slide.paragraphs"
                  :key="idx"
                  class="lead"
                >
                  <strong v-if="idx === 0"><i>"</i></strong
                  ><i class="text-md">{{ paragraph }}</i
                  ><strong v-if="idx === slide.paragraphs.length - 1"
                    ><i>"</i></strong
                  >
                </p>
                <p class="d-flex flex-row-reverse">
                  <i>- {{ slide.name }}</i>
                </p>
              </div>
            </div>
          </Slide>

          <template #addons>
            <Navigation class="text-white" />
            <!-- <Pagination /> -->
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "./core/Header.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "HomeComponent",
  components: {
    HeaderComponent,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  setup: () => {
    const slides = [
      {
        title: "Recommendation 1",
        paragraphs: [
          "After recently retiring from a successful 30 year career in the United States Air Force, I was fortunate to engage with Mile 20 Consulting. In working with Nancy Bayerle, I received professional guidance and mentorship as I began a major career search in the private sector. Working directly with Nancy Bayerle, I have received many benefits, including a concise, effective Executive resume, interview preparation, LinkedIn profile reviews, outreach guidance, and more.",
          "I highly and wholeheartedly recommend Nancy Bayerle and Mile 20 Consulting for anyone seeking to refine their job search, be it Military transition, a promotion or simply preparing ahead of time for a career move.",
        ],
        name: "Chief K.P., United States Air Force, transitioning to Corporate role",
      },
      {
        title: "Recommendation 2",
        paragraphs: [
          "During my recent transition from Germany to the U.S., I opted to work with a professional Career Strategist, Nancy Bayerle, of Mile 20 Consulting. Nancy is a career Technologist, with years of experience in Oracle’s National Security Group, Microsoft, AWS, and other Big Tech firms.",
          "Nancy's approach is a blend of deep industry knowledge, keen insight, and genuine care for her clients.",
          "She offers unique Job Strategy Sessions. Goal setting & tracking, live interview prep and the STAR method of interviewing are just a few of her impactful sessions. You'll learn how to distinguish yourself from the pack, track search results and negotiate salaries, plus find out how to onboard with intent.",
          "I highly recommend Mile 20 Consulting to anyone looking to gain a competitive edge in their career. Nancy's expertise and personalized approach are invaluable, whether you’re seeking a new opportunity or aiming to ascend in your current role.",
        ],
        name: "Harold J., Germany-based, Fortune 500 Solutions Architect, recently transitioned to United States Corporate role",
      },
    ];

    return {
      slides,
    };
  },
};
</script>

<style>
.carousel__slide {
  align-items: baseline !important;
}
</style>
