<template>
  <footer class="footer sticky-sm-bottom py-3 text-center">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <span class="text-muted"
            >&copy; 2023 - {{ new Date().getFullYear() }} Mile20 Recruiting
          </span>
        </div>
        <!-- <div class="col-12">
          <span><a class="link-primary">Privacy Policy</a></span>
        </div> -->
        <div class="col-12">
          <a href="https://www.linkedin.com/in/nancybayerle" target="_blank">
            <img class="m-2" src="@/assets/svg/linkedin.svg" />
          </a>
          <a href="mailto:nancy@mile20recruiting.org">
            <img class="m-1" src="@/assets/svg/mail.svg" />
          </a>
        </div>
        <div class="col-12">
          <span
            >Created by
            <a class="link-primary" href="https://patentpendingstudios.com"
              >Patent Pending Studios</a
            ></span
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>
