<template>
  <HeaderComponent
    :section-class="'hero-section-services'"
    :show-text="false"
    :header-text="'Services'"
  />
  <div id="recruiting-about" class="container mb-2 col-xxl-8 px-4">
    <div class="row flex-lg-row-reverse pt-5">
      <div class="col-10 col-sm-8 col-lg-6">
        <img
          src="../assets/images/recruitment-cropped.jpg"
          class="mb-2 mx-lg-auto img-fluid shadow rounded border border-2 border-dark"
          alt="Recruitment"
          loading="lazy"
        />
      </div>
      <div class="col-lg-6">
        <h1 class="display-5 fw-bold text-body-emphasis lh-1 mb-3">
          Recruiting
        </h1>
        <ul class="lead">
          <li>
            Fee-based Technology Recruiting for Client Companies (The company
            pays fee)
          </li>
          <li>
            Specialties, Cloud, Data, Cyber, seek Python, C, C++, FPGA,
            Embedded, Radar, more!
          </li>
        </ul>
        <p>
          <i>
            * While we do not guarantee finding you a new career, we DO
            guarantee your Prepare to Launch Satisfaction with our fee-based
            Resumes and Strategy Sessions.
          </i>
        </p>
        <p>Read more:</p>
        <div class="d-grid gap-2 d-md-flex justify-content-md-start">
          <a
            type="button"
            class="btn btn-outline-secondary btn-lg px-4 text-primary"
            href="#job-strategy-sessions"
          >
            Job Strategy Sessions
          </a>
          <a
            type="button"
            class="btn btn-outline-secondary btn-lg px-4 text-primary"
            href="#resume-revamp"
          >
            Resume Re-vamp
          </a>
          <a
            type="button"
            class="btn btn-outline-secondary btn-lg px-4 text-primary"
            href="#career-transitions"
          >
            Career Transitions
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="display-5 fw-bold text-body-emphasis lh-1 mb-3">Services</h1>
      </div>
    </div>
  </div>
  <div id="job-strategy-sessions" class="container pt-5">
    <div class="row">
      <div class="col-lg-6 col-12 order-1 order-lg-0">
        <CardComponent :title="'Job Strategy Sessions'">
          <ul>
            <li>Goal-Setting</li>
            <li>Live Interview Preparation</li>
            <li>Outreach Techniques</li>
            <li>Negotiating Pay and Benefits</li>
            <li>"I got the job, now what?"</li>
          </ul>
        </CardComponent>
      </div>
      <div class="col-lg-6 col-12 order-0 order-lg-1">
        <img
          src="../assets/images/strategy-sessions.jpg"
          class="border border-2 border-dark mt-3 rounded shadow img-fluid"
        />
      </div>
    </div>
  </div>
  <div id="resume-revamp" class="container pt-5">
    <div class="row">
      <div class="col-lg-6 col-12 order-0 order-lg-0">
        <img
          src="../assets/images/resume-revamp.jpg"
          class="border border-2 border-dark mt-3 rounded shadow img-fluid"
        />
      </div>
      <div class="col-lg-6 col-12 order-1 order-lg-1">
        <CardComponent :title="'Resume Re-vamp'">
          <ul>
            <li>Make your tenure talk!</li>
            <li>Professional writing by a Human</li>
            <li>Customized Resumes</li>
            <li>Fast turnaround - RUSH available</li>
          </ul>
        </CardComponent>
      </div>
    </div>
  </div>
  <div id="career-transitions" class="container pt-5">
    <div class="row">
      <div class="col-lg-6 col-12 order-1 order-lg-0">
        <CardComponent :title="'Career Transitions'">
          <ul>
            <li>Transitioning Military? We've got you!</li>
            <li>Re-entering Corporate World? We can help!</li>
            <li>Laid off? Special insights just for you!</li>
            <li>Professional programs tailored to YOU</li>
          </ul>
        </CardComponent>
      </div>
      <div class="col-lg-6 col-12 order-0 order-lg-1">
        <img
          src="../assets/images/career-transitions.jpg"
          class="border border-2 border-dark mt-3 rounded shadow img-fluid"
        />
      </div>
    </div>
  </div>
  <div class="container text-center pt-5">
    <h1 class="display-4 fw-bold text-body-emphasis">
      Ready to take the next step?
    </h1>
    <div class="col-lg-6 mx-auto">
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
        <router-link
          to="/start-my-launch"
          type="button"
          class="btn btn-primary btn-lg px-4 me-sm-3"
        >
          Start Your Launch
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import CardComponent from "./core/Card.vue";
import HeaderComponent from "./core/Header.vue";

export default {
  name: "ServicesComponent",
  components: {
    CardComponent,
    HeaderComponent,
  },
};
</script>
