<template>
  <section :class="sectionClass">
    <header class="jumbotron jumbotron-fluid home-title">
      <div v-if="showText" class="container-fluid text-center">
        <h1 class="display-3 text-white fw-bold h1-header">
          Your <span class="h1-gradient">Journey</span> Starts Here
        </h1>
        <p class="lead pb-4 text-white h1-header">
          Career move? Resume upgrade? Social media presence? That's our job!
        </p>
        <p>
          <router-link
            to="/start-my-launch"
            class="btn btn-secondary btn-gradient shadow btn-lg text-white"
            type="button"
            >Start Your Launch</router-link
          >
        </p>
      </div>
      <div v-if="!showText" class="row mt-5">
        <h1 class="display-3 mt-5 text-white h1-header">{{ headerText }}</h1>
      </div>
    </header>
  </section>
</template>

<script>
export default {
  name: "HeaderComponent",
  props: {
    sectionClass: {
      required: true,
      type: String,
    },
    showText: {
      required: true,
      type: Boolean,
      default: false,
    },
    headerText: {
      required: false,
      type: String,
      default: "Title",
    },
  },
};
</script>
